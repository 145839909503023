<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Favourite</title>
    <path
      d="M7.85312 12.5664L4.04651 14.8595C3.87835 14.9666 3.70254 15.0124 3.51909 14.9971C3.33564 14.9818 3.17512 14.9207 3.03753 14.8137C2.89994 14.7067 2.79293 14.5729 2.71649 14.4124C2.64005 14.2519 2.62476 14.0722 2.67063 13.8735L3.67961 9.53946L0.308692 6.62717C0.155816 6.48958 0.0602684 6.33289 0.0220494 6.15708C-0.0161696 5.98127 -0.00470388 5.80929 0.0564465 5.64112C0.117597 5.47296 0.209322 5.33537 0.331623 5.22836C0.453924 5.12135 0.622088 5.05255 0.836114 5.02198L5.28481 4.63214L7.00466 0.550353C7.0811 0.366902 7.19958 0.229314 7.3601 0.137588C7.52062 0.0458628 7.68496 0 7.85312 0C8.02129 0 8.18563 0.0458628 8.34615 0.137588C8.50667 0.229314 8.62515 0.366902 8.70158 0.550353L10.4214 4.63214L14.8701 5.02198C15.0842 5.05255 15.2523 5.12135 15.3746 5.22836C15.4969 5.33537 15.5886 5.47296 15.6498 5.64112C15.7109 5.80929 15.7224 5.98127 15.6842 6.15708C15.646 6.33289 15.5504 6.48958 15.3976 6.62717L12.0266 9.53946L13.0356 13.8735C13.0815 14.0722 13.0662 14.2519 12.9898 14.4124C12.9133 14.5729 12.8063 14.7067 12.6687 14.8137C12.5311 14.9207 12.3706 14.9818 12.1872 14.9971C12.0037 15.0124 11.8279 14.9666 11.6597 14.8595L7.85312 12.5664Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcFavourite',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>
